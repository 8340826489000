import logo from './assets/img/TsoomLCh.png'; 
import './App.css';
import qrSantiago from './assets/img/Santiago.png'; 
import qr_Tsoom from './assets/img/TsoomQR.png'; 
import qr_Jaime from './assets/img/JaimeQR.png'; 


function App() {
  return (
    <div>
    <div className="container my-backgorund">
    <img src={logo} alt="Logo" className="logo" />
    <h1 className="title">Próximamente...</h1>
    <div className="qr-container">
      <div className="qr-item">
        <div className='qr-image'>
        <img src={qrSantiago} alt="QR Santiago" />
        </div>
        <p>Santiago Gómez</p>
        <p className="mt-10">(Ventas)</p>
      </div>
      <div className="qr-item">
      <div className='qr-image'>
        <img src={qr_Tsoom} alt="QR Tsoom" />
        </div>
        <p>Tsoom Tecnologías</p>
        <p className="mt-10">(Cotizaciones)</p>
      </div>
      <div className="qr-item">
      <div className='qr-image'>
        <img src={qr_Jaime} alt="QR Jaime" />
        </div>
        <p>Jaime Gómez</p>
        <p className="mt-10">(Ventas)</p>
      </div>
    </div>
  </div>
  </div>
  );
}

export default App;
